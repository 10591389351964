
import { defineComponent } from "vue";
import { mapState } from "vuex";
import axios from "axios";
import moment, { Moment } from "moment-timezone";

import AppPage from "../components/AppPage.vue";
import Preloader from "../components/Preloader.vue";
import DataTable from "../components/DataTable.vue";
import DateRangeInput from "../components/DateRangeInput.vue";
import SubsetInput from "../components/SubsetInput.vue";
import PushStats from "../components/PushStats.vue";

interface DataModel {
  loading: boolean;
  filter: Filter;
  options: {
    campaigns: Array<Option>;
    articles: Array<Option>;
    geos: Array<Option>;
    designs: Array<Option>;
    devices: Array<Option>;
    page_types: Array<Option>;
    sid1: Array<Option>;
    sid2: Array<Option>;
    sid3: Array<Option>;
    sid4: Array<Option>;
    domain_names: Array<Option>;
  };
  columns: Array<Column>;
  mainGroupOptions: Array<Column>;
  grouping: Array<string | null>;
  rows: Array<any>;
  totalRows: number;
  totals: any;
  isPushTab: boolean;
}

interface ComputedModel {
  query: any;
  effectiveGroupingFieldId: any;
  secondaryGroupOptions: any;
  user: any;
}

interface MethodsModel {
  setColumns(columns: Column[]): void;
  load(): void;
  clear(): void;
  download(): void;
  filterOptions(collection: string, query?: string, field?: string): void;
  setGrouping(grouping_type: string, value: string | null): void;
  getNewColumnsWithGrouping(): Array<Column>;
}

interface Filter {
  period: Moment[];
  timezone: string;
  campaign_ids: Array<number>;
  article_ids: Array<number>;
  geo_ids: Array<number>;
  design_ids: Array<number>;
  device_ids: Array<number>;
  page_type_ids: Array<number>;
  sid1: Array<string>;
  sid2: Array<string>;
  sid3: Array<string>;
  sid4: Array<string>;
  pagination: null | Pagination;
  sorting: Sorting;
  domain_names: Array<string>;
}

interface Sorting {
  column: string;
  direction: string;
}

interface Option {
  id: number | string;
  name: string;
}

interface Column extends Option {
  selected: boolean;
  freezed?: boolean;
  group: number;
}

interface Pagination {
  limit: number;
  offset: number;
}

const groupingFields = [
  "date",
  "campaign_name",
  "article_name",
  "geo_name",
  "design_name",
  "device_name",
  "page_type_name",
  "sid1",
  "sid2",
  "sid3",
  "sid4",
];

function getDefaultFilter(): Filter {
  return {
    period: [moment().endOf("day").subtract(29, "days"), moment().endOf("day")],
    timezone: moment.tz.guess(),
    campaign_ids: [],
    article_ids: [],
    geo_ids: [],
    design_ids: [],
    device_ids: [],
    page_type_ids: [],
    sid1: [],
    sid2: [],
    sid3: [],
    sid4: [],
    pagination: { limit: 10, offset: 0 },
    sorting: { column: "date", direction: "desc" },
    domain_names: [],
  };
}

const allColumns = [
  { id: "date", name: "Дата", selected: true, sortable: true, group: 1 },
  {
    id: "campaign_name",
    name: "Кампания",
    selected: false,
    sortable: true,
    type: "text",
    group: 1,
  },
  {
    id: "article_name",
    name: "Статья",
    selected: false,
    sortable: true,
    type: "text",
    group: 1,
  },
  {
    id: "geo_name",
    name: "Гео",
    selected: false,
    sortable: true,
    type: "text",
    group: 1,
  },
  {
    id: "design_name",
    name: "Дизайн",
    selected: false,
    sortable: false,
    type: "text",
    group: 1,
  },
  {
    id: "device_name",
    name: "Устройство",
    selected: false,
    sortable: false,
    type: "text",
    group: 1,
  },
  {
    id: "page_type_name",
    name: "Тип страницы",
    selected: false,
    sortable: false,
    type: "text",
    group: 1,
  },
  { id: "sid1", name: "SID 1", selected: false, sortable: true, group: 1 },
  { id: "sid2", name: "SID 2", selected: false, sortable: true, group: 1 },
  { id: "sid3", name: "SID 3", selected: false, sortable: true, group: 1 },
  { id: "sid4", name: "SID 4", selected: false, sortable: true, group: 1 },

  { id: "bots", name: "Боты", selected: true, sortable: true, group: 2 },
  {
    id: "visits",
    name: "Переходы",
    selected: true,
    sortable: true,
    group: 2,
  },
  { id: "users", name: "Уники", selected: true, sortable: true, group: 2 },
  { id: "views", name: "Показы", selected: true, sortable: true, group: 2 },
  { id: "clicks", name: "Клики", selected: true, sortable: true, group: 2 },
  { id: "epc", name: "EPC", selected: true, sortable: true, group: 2 },
  { id: "ctr", name: "CTR, %", selected: true, sortable: true, group: 2 },
  { id: "cr", name: "CR, %", selected: true, sortable: true, group: 2 },
  {
    id: "approve",
    name: "Аппрув, %",
    selected: true,
    sortable: true,
    group: 2,
  },
  {
    id: "valid_leads",
    name: "Лиды",
    selected: true,
    sortable: true,
    group: 2,
  },
  {
    id: "pending_leads",
    name: "Обработка, шт.",
    selected: true,
    sortable: true,
    group: 2,
  },
  {
    id: "approved_leads",
    name: "Апрув, шт.",
    selected: true,
    sortable: true,
    group: 2,
  },
  {
    id: "rejected_leads",
    name: "Отмена, шт.",
    selected: true,
    sortable: true,
    group: 2,
  },
  {
    id: "trash_leads",
    name: "Треш, шт.",
    selected: true,
    sortable: true,
    group: 2,
  },
  {
    id: "waiting_usd",
    name: "В ожидании, USD",
    selected: true,
    sortable: true,
    group: 2,
  },
  {
    id: "income_usd",
    name: "Доход, USD",
    selected: true,
    sortable: true,
    group: 2,
  },
  {
    id: "rejected_usd",
    name: "Отклонено, USD",
    selected: true,
    sortable: true,
    group: 2,
  },
  {
    id: "extra_monetized_usd",
    name: "Домонет. USD",
    selected: true,
    sortable: true,
    group: 2,
  },
  {
    id: "waiting_euro",
    name: "В ожидании, EUR",
    selected: true,
    sortable: true,
    group: 2,
  },
  {
    id: "income_euro",
    name: "Доход, EUR",
    selected: true,
    sortable: true,
    group: 2,
  },
  {
    id: "rejected_euro",
    name: "Отклонено, EUR",
    selected: true,
    sortable: true,
    group: 2,
  },
  {
    id: "extra_monetized_euro",
    name: "Домонет. EUR",
    selected: true,
    sortable: true,
    group: 2,
  },
  {
    id: "waiting_rub",
    name: "В ожидании, RUB",
    selected: false,
    sortable: true,
    group: 2,
  },
  {
    id: "income_rub",
    name: "Доход, RUB",
    selected: false,
    sortable: true,
    group: 2,
  },
  {
    id: "rejected_rub",
    name: "Отклонено, RUB",
    selected: false,
    sortable: true,
    group: 2,
  },
  {
    id: "extra_monetized_rub",
    name: "Домонет. RUB",
    selected: false,
    sortable: true,
    group: 2,
  },
];

function getStoredColumns() {
  const json = localStorage.getItem("statistics.columns");
  if (!json) {
    return allColumns;
  } else {
    return JSON.parse(json);
  }
}

function storeColumns(columns: Array<Column>) {
  localStorage.setItem("statistics.columns", JSON.stringify(columns));
}

function storeGrouping(grouping: Array<string | null>) {
  localStorage.setItem("statistics.grouping", JSON.stringify(grouping));
}

function getGroupingColumns() {
  return allColumns.filter((x) => x.group === 1);
}

function getGroupParams() {
  const json = localStorage.getItem("statistics.grouping");

  if (!json) {
    return ["date", ""];
  } else {
    return JSON.parse(json);
  }
}

export default defineComponent<DataModel & ComputedModel & MethodsModel>({
  components: {
    AppPage,
    Preloader,
    DataTable,
    DateRangeInput,
    SubsetInput,
    PushStats,
  },
  data(): DataModel {
    return {
      loading: false,
      filter: getDefaultFilter(),
      options: {
        campaigns: [],
        articles: [],
        geos: [],
        designs: [],
        devices: [],
        page_types: [],
        sid1: [],
        sid2: [],
        sid3: [],
        sid4: [],
        domain_names: [],
      },
      mainGroupOptions: getGroupingColumns(),
      columns: getStoredColumns(),
      grouping: getGroupParams(),
      rows: [],
      totalRows: 0,
      totals: {},
      isPushTab: false,
    };
  },
  computed: {
    query() {
      return {
        q: JSON.stringify({
          filter: this.filter,
          grouping: this.grouping,
          sorting: this.filter.sorting,
          pagination: this.filter.pagination,
        }),
      };
    },
    secondaryGroupOptions() {
      return getGroupingColumns().filter((x) => x.id !== this.grouping[0]);
    },
    effectiveGroupingFieldId() {
      return this.grouping[0];
    },
    ...mapState(["user"]),
  },
  watch: {
    columns(newColumns) {
      storeColumns(newColumns);
    },
  },
  async created() {
    this.load();
  },
  mounted() {
    this.$watch("filter.sorting", this.load);
    this.$watch("filter.pagination", this.load);
  },
  methods: {
    getNewColumnsWithGrouping() {
      let newColumns = this.columns.filter(
        (c) => c.id !== this.grouping[0] && c.id !== this.grouping[1]
      );

      const firstColumn = this.columns.find((c) => c.id === this.grouping[0]);
      const secondColumn = this.columns.find((c) => c.id === this.grouping[1]);

      if (this.grouping[1]?.length) {
        newColumns.unshift(firstColumn as Column, secondColumn as Column);
      } else {
        newColumns.unshift(firstColumn as Column);
      }

      return newColumns;
    },
    swapGrouping() {
      let mainGrouping, secondaryGrouping;

      mainGrouping = this.grouping[1];
      secondaryGrouping = this.grouping[0];

      this.setGrouping("main", mainGrouping);
      this.setGrouping("secondary", secondaryGrouping);

      storeGrouping(this.grouping);
    },
    setGrouping(grouping_type: string, value: string | null) {
      if (value === this.grouping[1]) {
        this.grouping[1] = this.grouping[0];
      }

      if (value === this.grouping[0]) {
        this.grouping[0] = this.grouping[1];
      }

      if (grouping_type === "main") {
        this.grouping[0] = value;
      } else {
        this.grouping[1] = value;
      }

      this.columns.map((c) => {
        if (c.group === 1) {
          if (c.id === this.grouping[0] || c.id === this.grouping[1]) {
            c.selected = true;
          } else {
            c.selected = false;
          }
        }
      });

      this.columns = this.getNewColumnsWithGrouping();
      storeGrouping(this.grouping);

      this.load();
    },
    setColumns(newColumns: Column[]) {
      this.columns = newColumns.map((c) => ({
        ...c,
        selected: c.selected,
      }));

      this.filter.pagination!.offset = 0;
    },
    async filterOptions(collection: string, query = "", field?: string) {
      const { data } = await axios.get("/api/" + collection + "/lookup", {
        params: { q: JSON.stringify({ query }) },
      });
      const key:
        | "sid1"
        | "sid2"
        | "sid3"
        | "sid4"
        | "designs"
        | "geos"
        | "campaigns"
        | "articles"
        | "page_types"
        | "domain_names"
        | "devices" = (field || collection) as any;
      this.options[key] = data;
    },
    async load() {
      this.loading = true;
      try {
        const { data } = await axios.get("/api/statistics/get2", {
          params: this.query,
        });
        this.rows = data.rows;
        this.totalRows = data.totalRows;
        this.totals = data.totals;

        this.filterOptions("sid1");
        this.filterOptions("sid2");
        this.filterOptions("sid3");
        this.filterOptions("sid4");
        this.filterOptions("designs");
        this.filterOptions("geos");
        this.filterOptions("campaigns");
        this.filterOptions("news", "", "articles");
        this.filterOptions("page-types", "", "page_types");
        this.filterOptions("devices");
        this.filterOptions("domain_names");
      } catch (e) {
        this.$router.push(`/error/${(e.response && e.response.status) || 500}`);
      }
      this.loading = false;
    },
    clear() {
      this.filter = getDefaultFilter();
      this.grouping = ["date", ""];
      storeGrouping(this.grouping);
      this.columns.map((c) => {
        if (c.id !== this.grouping[0] && c.group === 1) {
          c.selected = false;
        } else {
          c.selected = true;
        }
      });

      this.columns = this.getNewColumnsWithGrouping();
      this.load();
    },
    download() {
      window.open(
        `/api/statistics/download?q=${encodeURIComponent(this.query.q)}`,
        "_blank"
      );
    },
  },
});
